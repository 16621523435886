import React from "react";
import Dropzone from "dropzone-exif-fix";
import { Button, Progress, Spinner, Row, Col } from "reactstrap";
import { APIBaseUrl } from "../../../services/API";
import EXIF from "exif-js";

Dropzone.autoDiscover = false;
export default function PhotoStep({changeStep, nextStep, previousStep, onSave, showNotification}) {
	const [showImageUploadingSpinner, setShowImageUploadingSpinner] = React.useState(false);
	const [showUploadDropzone, setShowUploadDropzone] = React.useState(true);
	const [fileUploadProgress, setFileUploadProgress] = React.useState(0);
	const [errorText, setErrorText] = React.useState('');
	React.useEffect(() => {
    	window.EXIF = EXIF;
		// this variable is to delete the previous image from the dropzone state
		// it is just to make the HTML DOM a bit better, and keep it light
		let currentSingleFile = undefined;
		// single dropzone file - accepts only images
		new Dropzone(document.getElementById("addCatchImageUploadDropzone"), {
			url: APIBaseUrl + "/fish/imageUpload.php",
			dictDefaultMessage: "Drop an image or click here to upload",
			createImageThumbnails: false,
			maxFiles: 1,
			acceptedFiles: "image/*",
			resizeHeight: 400,
			// resizeWidth: 400,
			resizeQuality: 1,
			previewTemplate: document.querySelector("#blankDzFishPhotoUploadTemplate")
				.innerHTML,
			init: function () {
				this.on("addedfile", function (file) {
					if (currentSingleFile) {
						this.removeFile(currentSingleFile);
					}
					currentSingleFile = file;
					setShowImageUploadingSpinner(true);
					setShowUploadDropzone(false);
				});
				this.on("sending", function (file, xhr, formData) {
					formData.append("getLocation", true);
				});
				this.on("uploadprogress", function (file, progress, bytesSent) {
					setFileUploadProgress(progress);
				});
				this.on("success", function (file, responseData) {
					setShowImageUploadingSpinner(false);
					setShowUploadDropzone(false);
					responseData = JSON.parse(responseData);
					const subData = [
						{
							key: "photo",
							val: responseData.filePath,
						},
					];
					let nextStep = 4;
					if (!responseData.data) {
						nextStep = 2;
					} else {
						for (const property in responseData.data) {
								subData.push({
									key: property,
									val: responseData.data[property],
								});
							}
						if (responseData.data.airTemp === null) {
							nextStep = 3;
						} else {
							showNotification(
								"default",
								"Great!",
								"We were able to get the location and weather from the photo"
							);
						}
					}
					console.log(subData);
					onSave(subData);
					changeStep(nextStep);
				});
				this.on("error", function (file, errorMessage) {
					setShowImageUploadingSpinner(false);
					setShowUploadDropzone(true);
					setErrorText("Photo Upload error. Please try again. " + errorMessage);
					console.error("Photo Upload error. Please try again. " + errorMessage);
				});
			},
		});
  	}, []);
		return (
			<>
				<h3>Upload a photo of your fish </h3>
				{showUploadDropzone && (
					<Row>
						<Col>
							<div
								className="dropzone dropzone-single mb-3"
								id="addCatchImageUploadDropzone"
							>
								<div className="fallback">
									<div className="custom-file">
										<input
											className="custom-file-input"
											id="projectCoverUploads"
											type="file"
										/>
										<label
											className="custom-file-label"
											htmlFor="projectCoverUploads"
										>
											Choose file
										</label>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				)}
				{showImageUploadingSpinner && (
					<>
						<Row>
							<Col>
								<div className="text-center mb-5">
									<Spinner color="primary" />
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<Progress
									className="progress-xs mb-0"
									color="success"
									max="100"
									value={fileUploadProgress}
								/>
							</Col>
						</Row>
					</>
				)}

				<div className="text-muted font-italic">
					<small className="text-danger font-weight-700">
						{errorText}
					</small>
				</div>
				{!showImageUploadingSpinner && (
					<div className="modal-footer p-0">
						<Button
							color="secondary"
							data-dismiss="modal"
							type="button"
							onClick={() => previousStep()}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							type="button"
							onClick={() => nextStep()}
						>
							Skip this step
						</Button>
					</div>
				)}

				<div id="blankDzFishPhotoUploadTemplate">
					<div></div>
				</div>
			</>
		);
	}
