import React from "react";
// react library for routing
import { useLocation, Route, Routes, Navigate } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import Login from "views/LoggedOut/Login";
import Logout from "views/LoggedOut/Logout";
import Register from "views/LoggedOut/Register";
import FAQ from "views/LoggedOut/FAQ";
import Home from "views/LoggedOut/Home";

function Auth() {
  const routes = [
    	{
        path: "/login",
        name: "Login",
        miniName: "L",
        colorClass: "text-orange",
        component: <Login />,
        layout: "/auth",
      },
      {
        path: "/logout",
        name: "Logout",
        miniName: "LO",
        colorClass: "text-orange",
        component: <Logout />,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        colorClass: "text-orange",
        component: <Register />,
        layout: "/auth",
      },
      {
        path: "/faq",
        name: "FAQ",
        colorClass: "text-orange",
        miniName: "FAQ",
        component: <FAQ />,
        layout: "/auth",
      },
      {
        path: "/home",
        name: "Home",
        miniName: 'HM',
        colorClass: "text-orange",
        component: <Home />,
        layout: "/auth",
      }
  ];
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <AuthNavbar />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      </div>
      <AuthFooter />
    </>
  );
}

export default Auth;
