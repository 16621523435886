import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Spinner,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import { AdminContext } from "layouts/Admin";
import { API } from "../../services/API";
import OfferedPlansTable from "components/Plans/OfferedPlansTable";

export default function SetupPlans({ reloadPageData }) {
  const [showLoading, setShowLoading] = React.useState(true);
  const [reloadTrigger, setReloadTrigger] = React.useState(1);
  const [catchMetadata, setCatchMetadata] = React.useState([]);
  const [newPlanModal, setNewPlanModal] = React.useState(false);
  const { showNotification, showModal } = React.useContext(AdminContext);
  const [name, setName] = React.useState(localStorage.getItem("fishuname"));
  const [planName, setPlanName] = React.useState("");
  const [planPrice, setPlanPrice] = React.useState("");

  React.useEffect(() => {
    document.title = "Setup Paid Plans";
    getCatchMetadata();
    setReloadTrigger(reloadTrigger + 1);
  }, [reloadPageData]);
  const getCatchMetadata = () => {
    API.get("planFiles/getCatchMetadataOptions.php")
      .then((responseJson) => {
        responseJson = responseJson.data;
        console.log(responseJson);
        let cmdarr = [];
        if (responseJson.status) {
          responseJson.data.forEach((retcmd) => {
            cmdarr.push({
              id: retcmd.id,
              name: retcmd.name,
              checked: true,
            });
          });

          setCatchMetadata(cmdarr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckboxChange = (e) => {
    let newCmdArr = [];
    let isChecked = e.target.checked;
    let cmdId = e.target.id.split("_")[1];
    catchMetadata.forEach((cmd) => {
      if (cmd.id === cmdId) {
        cmd.checked = isChecked;
      }
      newCmdArr.push(cmd);
    });
    setCatchMetadata(newCmdArr);
  };

  const save = () => {
    setShowLoading(true);
    const formData = new FormData();
    formData.append("name", planName);
    formData.append("price", planPrice);
    formData.append("catchMetadata", JSON.stringify(catchMetadata));
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    API.post("planFiles/plans.php", formData, config)
      .then((responseJson) => {
        responseJson = responseJson.data;
        console.log(responseJson);
        setReloadTrigger(reloadTrigger + 1);
        showNotification("success", "Saved", "Plan added!");
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
        showNotification(
          "error",
          "Issue",
          "Unable to save... please refresh and try again"
        );
      });
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={newPlanModal}
        size="lg"
        backdrop={true}
        toggle={() => setNewPlanModal(!newPlanModal)}
      >
        <div className="modal-header">
          <h5 className="modal-title">New Plan Details</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setNewPlanModal(!newPlanModal)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="edit-plan-name"
                    >
                      Plan Name
                    </label>
                    <Input
                      id="edit-plan-name"
                      placeholder="Bronze Plan"
                      type="text"
                      onChange={(e) => {
                        setPlanName(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="edit-plan-price"
                    >
                      Price per month ($)
                    </label>
                    <Input
                      id="edit-plan-price"
                      placeholder="9.99"
                      type="text"
                      onChange={(e) => {
                        setPlanPrice(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <label className="form-control-label">
                    Catch Details to Include
                  </label>
                  <hr className="mb-3 mt-1" />
                  {catchMetadata.map(function (cmd, i) {
                    return (
                      <div key={cmd.id} className="mt-3">
                        <label className="custom-toggle mr-1">
                          <input
                            defaultChecked
                            type="checkbox"
                            id={"cmdcb_" + cmd.id}
                            onChange={handleCheckboxChange}
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                        <span className="ml-4 pb-2">{cmd.name}</span>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer p-0">
          <Button color="primary" type="button" onClick={() => save()}>
            Save
          </Button>
        </div>
      </Modal>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "410px",
          backgroundImage:
            'url("' + require("assets/img/theme/profile-cover2.jpg") + '")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">{name}</h1>
              <p className="text-white mt-0 mb-5">
                This is where you can setup paid plans for your followers to see
                your fish. You will be able to select what information each plan
                level has access to. For example, you could have a cheap plan
                that shows something like water temp and lure. Then an expensive
                plan that also shows them the location of the catch. <br />
                You are limited to 4 plans.
              </p>
              <Button
                className="btn-neutral"
                color="default"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setNewPlanModal(true);
                }}
              >
                Add Plan
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid>
        <Row className="row-grid justify-content-center">
          <Col lg="10">
            <OfferedPlansTable reloadPageData={reloadTrigger} userId="-1" />
          </Col>
        </Row>
        <hr className="my-4" />

        {/* { (
          <Row className="justify-content-center" id="editPane">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit profile</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        saveEdits();
                      }}
                      size="sm"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="edit-name"
                          >
                            Name
                          </label>
                          <Input
                            defaultValue={profile.name}
                            id="edit-name"
                            placeholder="Name"
                            type="text"
                            onChange={(e) => {
                              setProfile((current) => ({
                                ...current,
                                name: e.target.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="edit-location"
                          >
                            City / State
                          </label>
                          <Input
                            defaultValue={profile.homeLocation}
                            id="edit-location"
                            placeholder="Richmond, VA"
                            type="text"
                            onChange={(e) => {
                              setProfile((current) => ({
                                ...current,
                                homeLocation: e.target.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="edit-water"
                          >
                            Home Water
                          </label>
                          <Input
                            defaultValue={profile.homeWater}
                            id="edit-water"
                            placeholder="James River"
                            type="text"
                            onChange={(e) => {
                              setProfile((current) => ({
                                ...current,
                                homeWater: e.target.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">
                    Profile Image
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="edit-profile-image"
                            lang="en"
                            type="file"
                            onChange={(e) => {
                              setProfileImage(e.target.files[0]);
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="edit-profile-image"
                          >
                            Select file
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">About me</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label className="form-control-label">About Me</label>
                      <Input
                        placeholder="A few words about you ..."
                        rows="4"
                        type="textarea"
                        defaultValue={profile.profileSummary}
                        onChange={(e) => {
                          setProfile((current) => ({
                            ...current,
                            profileSummary: e.target.value,
                          }));
                        }}
                      />
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Row>
        )} */}
      </Container>
    </>
  );
}
