import React from "react";
import {
	Row,
	Container,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import AuthHeader from "components/Headers/AuthHeader.js";
import Cookies from "universal-cookie";

export default function Logout() {
	const redir = useNavigate();
	React.useEffect(() => {
		let cookies = new Cookies();
		localStorage.removeItem("fishuem");
		localStorage.removeItem("fishuid");
		localStorage.removeItem("fishuname");
		// localStorage.removeItem("fishuexp");
		cookies.remove("fishuem", {path: '/'});
		cookies.remove("fishuid", {path: '/'});
		cookies.remove("fishuname", {path: '/'});
		console.log('lged out');
		redir("/auth/login");
  	}, [redir]);
		return (
			<>
				<AuthHeader title="" lead="" />
				<Container className="mt--8 pb-5">
					<Row className="justify-content-center">
						Logging Out
					</Row>
				</Container>
			</>
		);
};