import React from "react";
import {
  Modal,
  Button,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faBalanceScale,
  faCloudSun,
  faWater,
  faWind,
  faEyeSlash,
  faMoon,
  faCompress,
  faCloud,
  faWaveSquare,
  faTachometerAlt,
  faStickyNote,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { API } from "../../services/API";

export default function CatchInfo({
  catchId,
  openFlag,
  reloadPageData,
  showNotification,
  onClose,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [catchViewing, setCatchViewing] = React.useState(-1);
  const [catchInfo, setCatchInfo] = React.useState({});
  React.useEffect(() => {
    if (openFlag) {
      console.log(catchId);
      setCatchViewing(catchId);
      getCatchInfo(catchId);
      setShowModal(true);
    }
  }, [openFlag, catchId]);

  const getCatchInfo = (catchId) => {
    console.log(catchId);
    API.get("catchFiles/catches.php?id=" + catchId)
      .then((responseJson) => {
        responseJson = responseJson.data;
        console.log(responseJson);
        setShowLoading(false);
        if (responseJson.status) {
          setCatchInfo(responseJson.catch);
        } else {
          console.log("ERROR");
          console.log(responseJson);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        console.log(error);
      });
  };
  const toggle = () => {
    console.log("TGLER");
    setShowModal(!showModal);
    onClose();
  };
  const deleteCatch = () => {
    setShowLoading(true);
    const subData = new FormData();
    subData.append("id", catchViewing);
    subData.append("delete", 1);
    const successText =
      catchInfo.isFish == 1 ? "Catch Removed" : "Report Removed";
    API.post("catchFiles/catches.php", subData)
      .then(() => {
        reloadPageData();
        setShowLoading(false);
        toggle();
        showNotification("success", "Deleted", successText);
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
        toggle();
      });
  };
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={showModal}
      size="lg"
      backdrop={true}
      toggle={() => toggle()}
    >
      <div className="modal-header">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pb-0">
        {showLoading && <Spinner size="lg" color="primary" />}
        {!showLoading && catchInfo.id && (
          <Card className="card-profile">
            <div className="justify-content-center text-center catch-card-header-photo">
              {catchInfo.photo.length >= 4 && catchInfo.isFish == 1 && (
                <CardImg alt="..." src={catchInfo.photo} top />
              )}
              {/* {catchInfo.photo.length < 4  && catchInfo.isFish == 1 && (
									<CardImg
										alt="..."
										src={require("assets/img/brand/biteLogrLogos/LOGO.png")}
										top
									/>
								)} */}
            </div>
            <Row className="justify-content-center">
              <Col className="order-lg-2" lg="3">
                <div className="card-profile-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {catchInfo.speciesImage.length >= 4 && (
                      <img
                        alt="..."
                        className="rounded-circle bg-gradient-default"
                        src={catchInfo.speciesImage}
                      />
                    )}
                    {catchInfo.speciesImage.length < 4 && (
                      <img
                        alt="..."
                        className="rounded-circle bg-gradient-default"
                        src={require("assets/img/brand/biteLogrLogos/LogoOnly.png")}
                      />
                    )}
                  </a>
                </div>
              </Col>
            </Row>
            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div className="d-flex justify-content-between">
                {/* <Button
										className="mr-4"
										color="default"
										href="#pablo"
										onClick={(e) => e.preventDefault()}
										size="sm"
									>
										Connect
									</Button> */}
                <Button
                  className="float-right"
                  color="danger"
                  href="#delcatch"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteCatch();
                  }}
                  size="sm"
                >
                  Delete
                </Button>
              </div>
            </CardHeader>
            <CardBody className="pt-0 pb-0">
              <div className="text-center">
                <h5 className="h3">
                  {catchInfo.isFish == 1 ? catchInfo.species : "Report"}
                </h5>
                <div className="h5 font-weight-300">
                  <i className="ni location_pin mr-2" />
                  {catchInfo.formattedTimestamp}
                </div>
              </div>
              <Row>
                <ListGroup className="list w-100" flush>
                  {catchInfo.isFish == 0 && (
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <span
                            className={`avatar rounded-circle ${
                              catchInfo.notes !== "N/A" ? "bg-primasry" : ""
                            }`}
                          >
                            <FontAwesomeIcon icon={faStickyNote} />
                          </span>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              {catchInfo.notes}
                            </a>
                          </h4>
                        </div>
                        <Col className="col-auto">
                          <h6 className="text-muted text-uppercase ls-1 mb-1">
                            Notes
                          </h6>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                  {catchInfo.lure > -1 && (
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        {catchInfo.lureName !== "HIDDEN" && (
                          <>
                            <Col className="col-auto">
                              <span
                                className="avatar rounded-circle"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.lureImage.length > 4 && (
                                  <img
                                    alt="..."
                                    src={catchInfo.lureImage}
                                    onError={(e) => {
                                      e.onError = null;
                                      e.src =
                                        "assets/img/brand/biteLogrLogos/LogoOnly.png";
                                    }}
                                  />
                                )}
                                {catchInfo.lureImage.length <= 4 && (
                                  <img
                                    alt="..."
                                    src={require("assets/img/brand/biteLogrLogos/LogoOnly.png")}
                                  />
                                )}
                              </span>
                            </Col>
                            <div className="col ml--2">
                              <h4 className="mb-0">
                                <a
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {catchInfo.lureName}
                                </a>
                              </h4>
                              <small>
                                {catchInfo.lureSize} {catchInfo.lureColor}
                              </small>
                            </div>
                          </>
                        )}
                        {catchInfo.lureName === "HIDDEN" && (
                          <>
                            <Col className="col-auto">
                              <span
                                className="avatar rounded-circle bg-primary"
                                onClick={(e) => e.preventDefault()}
                              >
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </span>
                            </Col>
                            <div className="col ml--2">
                              <h4 className="mb-0">{catchInfo.lureName}</h4>
                            </div>
                          </>
                        )}
                        <Col className="col-auto">
                          <h6 className="text-muted text-uppercase ls-1 mb-1">
                            Lure
                          </h6>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                  {catchInfo.otherGearUsed !== "N/A" &&
                    catchInfo.lureName !== "HIDDEN" && (
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <span className="avatar rounded-circle bg-default">
                              <FontAwesomeIcon icon={faTools} />
                            </span>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.otherGearUsed}
                              </a>
                            </h4>
                          </div>
                          <Col className="col-auto">
                            <h6 className="text-muted text-uppercase ls-1 mb-1">
                              Gear
                            </h6>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <span
                          className={`avatar rounded-circle ${
                            catchInfo.latitude !== "N/A" ? "bg-success" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faMapMarkedAlt} />
                        </span>
                      </Col>
                      <div className="col ml--2">
                        {catchInfo.latitude !== "N/A" && (
                          <>
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.latitude !== "HIDDEN" && (
                                  <>
                                    {catchInfo.latitude}
                                    {", "}
                                    {catchInfo.longitude}
                                  </>
                                )}
                                {catchInfo.latitude === "HIDDEN" && (
                                  <>{catchInfo.latitude}</>
                                )}
                              </a>
                            </h4>
                            <small></small>
                          </>
                        )}
                        {catchInfo.latitude === "N/A" && (
                          <h4 className="mb-0">N/A</h4>
                        )}
                      </div>
                      <Col className="col-auto">
                        <h6 className="text-muted text-uppercase ls-1 mb-1">
                          Location
                        </h6>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <span
                          className={`avatar rounded-circle ${
                            catchInfo.weight !== "N/A" ||
                            catchInfo.length !== "N/A"
                              ? "bg-default"
                              : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faBalanceScale} />
                        </span>
                      </Col>
                      <div className="col ml--2">
                        {catchInfo.weight !== "N/A" && (
                          <>
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.weight}
                              </a>
                            </h4>
                            {catchInfo.length !== "N/A" && (
                              <small>{catchInfo.length}</small>
                            )}
                          </>
                        )}
                        {catchInfo.weight === "N/A" &&
                          catchInfo.length !== "N/A" && (
                            <h4 className="mb-0">{catchInfo.length}</h4>
                          )}
                        {catchInfo.weight === "N/A" &&
                          catchInfo.length === "N/A" && (
                            <h4 className="mb-0">N/A</h4>
                          )}
                      </div>
                      <Col className="col-auto">
                        <h6 className="text-muted text-uppercase ls-1 mb-1">
                          Size
                        </h6>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <span
                          className={`avatar rounded-circle ${
                            catchInfo.airTemp !== "N/A" ? "bg-warning" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faCloudSun} />
                        </span>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {catchInfo.airTemp}
                          </a>
                        </h4>
                        {catchInfo.weatherCondition !== "N/A" &&
                          catchInfo.weatherCondition !== "HIDDEN" && (
                            <small>{catchInfo.weatherCondition}</small>
                          )}
                      </div>
                      <Col className="col-auto">
                        <h6 className="text-muted text-uppercase ls-1 mb-1">
                          Weather
                        </h6>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  {catchInfo.airTemp !== "HIDDEN" && (
                    <>
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <span
                              className={`avatar rounded-circle ${
                                catchInfo.windDirection !== "N/A"
                                  ? "bg-danger"
                                  : ""
                              }`}
                            >
                              <FontAwesomeIcon icon={faWind} />
                            </span>
                          </Col>
                          <div className="col ml--2">
                            {catchInfo.windDirection !== "N/A" && (
                              <h4 className="mb-0">
                                <a
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {catchInfo.windDirection}
                                  {"  "}
                                  {catchInfo.windSpeed}
                                </a>
                              </h4>
                            )}
                            {catchInfo.windDirection === "N/A" && (
                              <h4 className="mb-0">N/A</h4>
                            )}
                          </div>
                          <Col className="col-auto">
                            <h6 className="text-muted text-uppercase ls-1 mb-1">
                              Wind
                            </h6>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <span
                              className={`avatar rounded-circle ${
                                catchInfo.moonPhase !== "N/A"
                                  ? "bg-primary"
                                  : ""
                              }`}
                            >
                              <FontAwesomeIcon icon={faMoon} />
                            </span>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.moonPhase}
                              </a>
                            </h4>
                          </div>
                          <Col className="col-auto">
                            <h6 className="text-muted text-uppercase ls-1 mb-1">
                              Moon Phase
                            </h6>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <span
                              className={`avatar rounded-circle ${
                                catchInfo.pressure !== "N/A" ? "bg-default" : ""
                              }`}
                            >
                              <FontAwesomeIcon icon={faCompress} />
                            </span>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.pressure}
                              </a>
                            </h4>
                          </div>
                          <Col className="col-auto">
                            <h6 className="text-muted text-uppercase ls-1 mb-1">
                              Barometric Pressure
                            </h6>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <span
                              className={`avatar rounded-circle ${
                                catchInfo.cloudiness !== "N/A"
                                  ? "bg-primary"
                                  : ""
                              }`}
                            >
                              <FontAwesomeIcon icon={faCloud} />
                            </span>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.cloudiness}
                              </a>
                            </h4>
                          </div>
                          <Col className="col-auto">
                            <h6 className="text-muted text-uppercase ls-1 mb-1">
                              % Cloud Cover
                            </h6>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </>
                  )}
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <span
                          className={`avatar rounded-circle ${
                            catchInfo.waterTemp !== "N/A" ||
                            catchInfo.waterClarity !== "N/A"
                              ? "bg-info"
                              : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faWater} />
                        </span>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">{catchInfo.waterTemp}</h4>
                        {catchInfo.waterClarity !== "N/A" &&
                          catchInfo.waterTemp !== "HIODDEN" && (
                            <small>{catchInfo.waterClarity}</small>
                          )}
                      </div>
                      <Col className="col-auto">
                        <h6 className="text-muted text-uppercase ls-1 mb-1">
                          Water
                        </h6>
                      </Col>
                    </Row>
                  </ListGroupItem>

                  {catchInfo.flowRate !== "N/A" &&
                    catchInfo.waterTemp !== "HIODDEN" && (
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <span className="avatar rounded-circle bg-info">
                              <FontAwesomeIcon icon={faWater} />
                            </span>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.flowRate}
                              </a>
                            </h4>
                          </div>
                          <Col className="col-auto">
                            <h6 className="text-muted text-uppercase ls-1 mb-1">
                              Flow Rate
                            </h6>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                  {catchInfo.waveHeight !== "N/A" &&
                    catchInfo.waterTemp !== "HIODDEN" && (
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <span className="avatar rounded-circle bg-primary">
                              <FontAwesomeIcon icon={faWaveSquare} />
                            </span>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.waveHeight}
                              </a>
                            </h4>
                          </div>
                          <Col className="col-auto">
                            <h6 className="text-muted text-uppercase ls-1 mb-1">
                              Wave Height
                            </h6>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                  {catchInfo.speed !== "N/A" &&
                    catchInfo.speed !== "HIDDEN" && (
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <span className="avatar rounded-circle bg-success">
                              <FontAwesomeIcon icon={faTachometerAlt} />
                            </span>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {catchInfo.speed}
                              </a>
                            </h4>
                          </div>
                          <Col className="col-auto">
                            <h6 className="text-muted text-uppercase ls-1 mb-1">
                              Trolling Speed
                            </h6>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                  {catchInfo.isFish == 1 && (
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <span
                            className={`avatar rounded-circle ${
                              catchInfo.notes !== "N/A" ? "bg-primasry" : ""
                            }`}
                          >
                            <FontAwesomeIcon icon={faStickyNote} />
                          </span>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              {catchInfo.notes}
                            </a>
                          </h4>
                        </div>
                        <Col className="col-auto">
                          <h6 className="text-muted text-uppercase ls-1 mb-1">
                            Notes
                          </h6>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
      <div className="modal-footer pt-0">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}
