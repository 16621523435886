import React from "react";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Container,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
	Row,
	Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import AuthHeader from "components/Headers/AuthHeader.js";
import Cookies from "universal-cookie";
import { API } from "services/API";

export default function Register() {
	const [nameInput, setNameInput] = React.useState('');
	const [emailInput, setEmailInput] = React.useState('');
	const [passInput, setPassInput] = React.useState('');
	const [retypePassInput, setRetypePassInput] = React.useState('');
	const [rememberMeInput, setRememberMeInput] = React.useState('');
	const [errorText, setErrorText] = React.useState('');
	const [showLoading, setShowLoading] = React.useState(false);
	React.useEffect(() => {
    	// createCalendar();
		localStorage.removeItem("fishuem");
		localStorage.removeItem("fishuid");
		localStorage.removeItem("fishuname");
		document.title = 'Register';
		// localStorage.removeItem("fishuexp");
    	// eslint-disable-next-line
  	}, []);
	let cookies = new Cookies();
	const redir = useNavigate();
	cookies.remove("fishuem", {path: '/'});
	cookies.remove("fishuid", {path: '/'});
	cookies.remove("fishuname", {path: '/'});

	const sendRegistration = () => {
		setShowLoading(true);
		const formData = new FormData();
		formData.append("name", nameInput);
		formData.append("email", emailInput);
		formData.append("password", passInput);
		API.post("userFiles/users.php", formData)
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				if (responseJson.status) {
					if (responseJson.exists === 2) {
						setShowLoading(false);
						setErrorText("Email already in use. Login link is in the header!");
					} else {
						if (rememberMeInput) {
							cookies.set("fishuem", responseJson.encEmail, { path: "/", sameSite: 'strict' });
							cookies.set("fishuid", responseJson.encId, { path: "/", sameSite: 'strict' });
							cookies.set("fishuname", responseJson.name, { path: "/", sameSite: 'strict' });
						} else {
							cookies.remove("fishuem");
							cookies.remove("fishuid");
							cookies.remove("fishuname");
						}
						localStorage.setItem("fishuem", responseJson.encEmail);
						localStorage.setItem("fishuid", responseJson.encId);
						localStorage.setItem("fishuname", responseJson.name);
						API.interceptors.request.use(function (config) {
							config.headers = {
								"Content-Type": "multipart/form-data",
								"X-EncId": localStorage.getItem("fishuid"),
								"X-EncEm": localStorage.getItem("fishuem"),
							};
							return config;
						});
						// localStorage.setItem("fishuexp", moment().add(15, "m").format());
						setTimeout(redir("/admin/homecatchlist"), 1000);
					}
				} else {
					setShowLoading(false);
					setErrorText("Error on our end. Try refreshing!");
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					showLoading: false,
					errorText: "Error on our end. Try refreshing!",
				});
			});
	};

	const onCreateAccount = () => {
		const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(emailInput)) {
			if (passInput.length < 2) {
				setErrorText("Password must be at least 6 characters");
			} else if (passInput !== retypePassInput) {
				setErrorText("Passwords do not match");
			} else {
				setErrorText('');
				sendRegistration();
			}
		} else {
			setErrorText("Invalid email");
		}
	};

		return (
			<>
				<AuthHeader title="" lead="" />
				<Container className="mt--8 pb-5">
					<Row className="justify-content-center">
						<Col lg="5" md="7">
							<Card className="bg-secondary shadow border-0">
								<CardHeader className="bg-transparent">
									<div className="text-center">
										<img
											alt="..."
											style={{ maxHeight: "200px" }}
											src={require("assets/img/brand/biteLogrLogos/LOGO.png")}
										/>
									</div>
									{/* <div className="text-muted text-center mt-4 mb-3">
										<small>Sign up with</small>
									</div>
									<div className="text-center">
										<Button
											className="btn-neutral btn-icon mr-4"
											color="default"
											href="#pablo"
											onClick={(e) => e.preventDefault()}
										>
											<span className="btn-inner--icon">
												<img
													alt="Facebook Logo"
													src={require("assets/img/icons/common/facebook.svg")}
												/>
											</span>
											<span className="btn-inner--text">Facebook</span>
										</Button>
										<Button
											className="btn-neutral btn-icon"
											color="default"
											href="#pablo"
											onClick={(e) => e.preventDefault()}
										>
											<span className="btn-inner--icon">
												<img
													alt="Google Logo"
													src={require("assets/img/icons/common/google.svg")}
												/>
											</span>
											<span className="btn-inner--text">Google</span>
										</Button>
									</div> */}
								</CardHeader>
								<CardBody className="px-lg-5 pb-lg-5">
									<div className="text-center text-muted mb-4">
										<small>Sign Up</small>
									</div>
									<Form role="form">
										<FormGroup>
											<InputGroup className="input-group-alternative mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-single-02" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Name"
													id="nameInput"
													type="text"
													autoComplete="new-name"
													onChange={(e) => {
														setNameInput(e.target.value);
													}}
												/>
											</InputGroup>
										</FormGroup>
										<FormGroup>
											<InputGroup className="input-group-alternative mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-email-83" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Email"
													id="emailInput"
													type="email"
													autoComplete="new-email"
													onChange={(e) => {
														setEmailInput(e.target.value);
													}}
												/>
											</InputGroup>
										</FormGroup>
										<FormGroup>
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-lock-circle-open" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Password"
													type="password"
													autoComplete="new-password"
													id="passInput"
													onChange={(e) => {
														setPassInput(e.target.value);
													}}
												/>
											</InputGroup>
										</FormGroup>
										<FormGroup>
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-lock-circle-open" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Re-type Password"
													type="password"
													autoComplete="new-password"
													id="retypePassInput"
													onChange={(e) => {
														setRetypePassInput(e.target.value);
													}}
												/>
											</InputGroup>
										</FormGroup>
										<div className="custom-control custom-control-alternative custom-checkbox">
											<input
												className="custom-control-input"
												id="rememberMeInput"
												type="checkbox"
												defaultChecked
												onChange={(e) => {
													setRememberMeInput(e.target.value);
												}}
											/>
											<label
												className="custom-control-label"
												htmlFor="rememberMeInput"
											>
												<span className="text-muted">Remember me</span>
											</label>
										</div>
										<div className="text-muted font-italic">
											<small className="text-danger font-weight-700">
												{errorText}
											</small>
										</div>
										<div className="text-center">
											{!showLoading && (
												<Button
													className="mt-4"
													color="primary"
													type="button"
													onClick={() => onCreateAccount()}
												>
													Create account
												</Button>
											)}
											{showLoading && <Spinner color="primary" />}
										</div>
									</Form>
								</CardBody>
							</Card>
							<Row className="mt-3">
								<Col xs="6">
									<a className="text-light" href="./login">
										<small>Already registered?</small>
									</a>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</>
		);
}

// export default Register;
