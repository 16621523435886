import React from "react";
import moment from "moment";
import {
	Button,
	Card,
	CardHeader,
	Form,
	FormGroup,
	Spinner,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	Container,
	Row,
	Col,
	Label,
	CardBody,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import ReactDatetime from "react-datetime";
import GoogleMapReact from "google-map-react";
import {
	mapStyles,
	mapApiKey,
	Marker,
	getCityStateFromGeoResults,
} from "variables/mapOptions.js";
import { getWeatherClasses } from "variables/weatherMethods.js";
import { API } from "services/API";
import WeatherConditionCatches from "components/Analysis/WeatherConditionCatches";
import WeatherSummaryCards from "components/Analysis/WeatherSummaryCards";
import { AdminContext } from "layouts/Admin";


export default function TripPlanner({reloadPageData}) {
	const [showLoading, setShowLoading] = React.useState(true);
	const [reloadTrigger, setReloadTrigger] = React.useState(1);
	const [showMap, setShowMap] = React.useState(true);
	const [mapCenter, setMapCenter] = React.useState({lat:-1,lng:-1});
	const [clickMarker, setClickMarker] = React.useState({lat:-1,lng:-1});
	const [latitude, setLatitude] = React.useState(-1);
	const [longitude, setLongitude] = React.useState(-1);
	const [dateTime, setDateTime] = React.useState(Date.now());
	const [waterTemp, setWaterTemp] = React.useState('');
	const [selectedAddress, setSelectedAddress] = React.useState('');
	const [weatherFetched, setWeatherFetched] = React.useState(false);
	const [selectionHeader, setSelectionHeader] = React.useState("Click on map to select location of your trip");
	const [weather, setWeather] = React.useState({});
	const [weatherClasses, setWeatherClasses] = React.useState({});
	const [geocoder, setGeocoder] = React.useState({});
	const { showNotification } = React.useContext(AdminContext);
	React.useEffect(() => {
		document.title = 'Trip Planner';
		resetSelection();
		getMapCenter();
		setReloadTrigger(reloadTrigger+1);
	}, [reloadPageData]);
	const mapLoaded = (map, maps) => {
		map.setOptions({
			styles: mapStyles,
		});
		map.setCenter(
			new maps.LatLng(mapCenter.lat, mapCenter.lng)
		);
		setGeocoder(new maps.Geocoder());
	};
	const mapClicked = (x, y, lat, lng, event) => {
			setClickMarker({
				lat: lat,
				lng: lng,
			});
	};
	const setLocationFromMap = (e) => {
		e.stopPropagation();
		setLatitude(clickMarker.lat);
		setLongitude(clickMarker.lng);
		setShowMap(false);
		geocoder.geocode(
			{ location: clickMarker },
			(results, status) => {
				if (status === "OK") {
					setSelectedAddress(getCityStateFromGeoResults(results));
				} else {
					console.log("Geocoder failed due to: " + status);
				}
			}
		);
	}
	const hideMarkerAndInfoWindow = (e) => {
		e.stopPropagation();
			setClickMarker({
				lat: 0,
				lng: 0,
			});
	}
	const getMapCenter = () => {
		navigator?.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
			setMapCenter({lat: lat, lng: lng});
			setShowLoading(false);

		}, () => {
			setShowLoading(false);
		});
		// API.get("catchFiles/getMapCenter.php")
		// 	.then((responseJson) => {
		// 		responseJson = responseJson.data;
		// 		if (
		// 			responseJson.data.latitude !== -1 ||
		// 			responseJson.data.longitude !== -1
		// 		) {
		// 			setMapCenter({
		// 					lat: parseFloat(responseJson.data.latitude),
		// 					lng: parseFloat(responseJson.data.longitude),
		// 				});
		// 			setShowLoading(false);
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 	});
	};
	const isAllowedDate = (current) => {
		var weekAhead = moment().add(7, "day");
		return current.isBefore(weekAhead);
	};
	const handleDateChange = (newDt) => {
		setDateTime(newDt);
	};
	const resetSelection = () => {
		// setShowLoading(false);
		setShowMap(true);
		setWeatherFetched(false);
		setClickMarker({lat:-1,lng:-1});
		setDateTime(Date.now());
		setWaterTemp('');
		setSelectionHeader("Click on map to select location of your trip");
	};
	
	const getWeather = () => {
		setShowLoading(true);
		const subData = new FormData();
		let newDt = 0;
		let headerDate = "";
		if (dateTime._isAMomentObject) {
			newDt = dateTime.format("X");
			headerDate = dateTime.format("MMMM Do YYYY, h:mm a");
		} else {
			newDt = parseInt(dateTime / 1000);
			headerDate = moment(dateTime).format("MMMM Do YYYY, h:mm a");
		}
		subData.append("timestamp", newDt);
		subData.append("latitude", latitude);
		subData.append("longitude", longitude);
		API.post("catchFiles/getWeather.php", subData)
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				if (responseJson.status) {
					if (responseJson.data.airTemp) {
						showNotification(
							"default",
							"Great!",
							"We were able to get the weather for that time and location"
						);
						setShowLoading(false);
						setWeatherFetched(true);
						setSelectionHeader(selectedAddress + ' - ' + headerDate);
						setWeather(responseJson.data);
						setWeatherClasses(getWeatherClasses(responseJson.data));
					} else {
						showNotification(
							"warning",
							"Ummmm",
							"We were unable to get the weather for that time and location"
						);
					}
				} else {
					showNotification(
						"warning",
						"Ummmm",
						"We were unable to get the weather for that time and location"
					);
				}
			})
			.catch((error) => {
				console.log(error);
				setShowLoading(false);
			});
	};

		return (
			<>
				<CardsHeader
					name="Fishing Trip Planner"
					parentName="Bite Logr"
					reloadTrigger={reloadTrigger}
				/>
				<Container className="mt--5" fluid>
					<Row>
						<Col xl="12">
							<Card className="mb-0">
								<CardHeader className="border-0">
									<Row className="align-items-center">
										<div className="col">
											<h3 className="mb-0">{selectionHeader}</h3>
										</div>
										<div className="col text-right">
											<Button
												color="warning"
												href="#pablo"
												onClick={resetSelection}
												size="sm"
											>
												Reset
											</Button>
										</div>
									</Row>
								</CardHeader>
								{!weatherFetched && (
									<CardBody>
										{showMap && !showLoading && (
											<>
												<div
													className="mb-4"
													style={{ height: "400px", width: "100%" }}
												>
													<GoogleMapReact
														bootstrapURLKeys={{ key: mapApiKey }}
														center={[
															mapCenter.lat,
															mapCenter.lng,
														]}
														defaultZoom={10}
														yesIWantToUseGoogleMapApiInternals
														onGoogleApiLoaded={({ map, maps }) =>
															mapLoaded(map, maps)
														}
														onClick={({ x, y, lat, lng, event }) =>
															mapClicked(x, y, lat, lng, event)
														}
													>
														<Marker
															lat={clickMarker.lat}
															lng={clickMarker.lng}
															show={
																clickMarker.lat !== 0 ||
																clickMarker.lng !== 0
															}
															textForInfoWindow="Set this as the location of the trip?"
															setLocationFromMap={(e) => {
																setLocationFromMap(e);
															}}
															hideMarkerAndInfoWindow={(e) => {
																hideMarkerAndInfoWindow(e);
															}}
														/>
													</GoogleMapReact>
												</div>
											</>
										)}
										{!showMap && !showLoading && (
											<>
												<h4 className="mb-3">{selectedAddress}</h4>
												<Form>
													<FormGroup>
														<Label className="form-control-label">
															Date/Time of Trip
														</Label>
														<InputGroup className="input-group-alternative">
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<i className="ni ni-calendar-grid-58" />
																</InputGroupText>
															</InputGroupAddon>
															<ReactDatetime
																value={dateTime}
																inputProps={{
																	placeholder: "Select Date and Time",
																}}
																onChange={(newDt) => {
																	handleDateChange(newDt);
																}}
																isValidDate={isAllowedDate}
															/>
														</InputGroup>
													</FormGroup>
													<FormGroup>
														<Label className="form-control-label">
															Temp of water if known
														</Label>
														<Input
															placeholder="degrees"
															id="waterTemp"
															type="text"
															onChange={(e) => {
																setWaterTemp(e.target.value);
															}}
														/>
													</FormGroup>
												</Form>
												<Button
													disabled={showLoading}
													color="primary"
													type="button"
													onClick={() => getWeather()}
												>
													Next
												</Button>
											</>
										)}
										{showLoading && (
											<div className="text-center">
												<Spinner size="lg" color="primary" />
												<h4>Getting weather forecast...</h4>
											</div>
										)}
									</CardBody>
								)}
							</Card>
						</Col>
					</Row>
					{weatherFetched && (
						<>
							<Row>
								<Col xl="12">
									<WeatherSummaryCards
										weather={weather}
										weatherClasses={weatherClasses}
									/>
								</Col>
							</Row>
							<Row>
								<Col xl="12">
									<WeatherConditionCatches
										weather={weather}
										waterTemp={waterTemp}
										weatherClasses={weatherClasses}
										showNotification={showNotification}
									/>
								</Col>
							</Row>
						</>
					)}
				</Container>
			</>
		);
	}